import { Paginator, Platform, SortDirection } from '@app/classes';
import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { PayrollIncomeType } from '@time-tracking/interfaces/settings.interface';
import {
    ApproveButtonColumn,
    DateColumn,
    HoursColumn,
    HourTypeColumn,
    NoteColumn,
    ProjectColumn,
    StartEndTimeColumn,
    StatusColumn,
} from './columns';

const deleteOption: MenuColumnItem = {
    label: 'time-tracking.delete',
    event: 'delete',
};

const editOption: MenuColumnItem = {
    label: 'time-tracking.edit',
    event: 'edit',
};

export class TimesheetsEmployeeIndexTable extends Table {
    showMenuColumnOptions = true;
    sortDirection = SortDirection.DESC;

    constructor(
        protected auth: AuthService,
        private employeeId: number,
        private hasPayrollIntegration: boolean,
        private hasTimeClock: boolean,
        private payrollIncomeTypes: PayrollIncomeType[] = []
    ) {
        super(auth);

        this.boot();
    }

    getLink(timesheetEntry: TimesheetEntry): (string | number)[] {
        // only admins can edit approved timesheets
        if (this.auth.cant(Platform.timeTracking.permission.view) && timesheetEntry.isApproved) {
            return [];
        }

        return [timesheetEntry.id, 'edit'];
    }

    setColumns(): void {
        const columns = [new DateColumn(), new ProjectColumn(), new NoteColumn()];

        if (this.hasPayrollIntegration) {
            columns.push(new HourTypeColumn(this.payrollIncomeTypes));
        }

        if (this.hasTimeClock) {
            columns.push(new StartEndTimeColumn());
        }

        columns.push(...[new HoursColumn(), new StatusColumn(), new ApproveButtonColumn(this.auth, this.employeeId)]);

        this.columns = columns;
    }

    getMenuColumnOptionsForRow(timesheetEntry: TimesheetEntry): MenuColumnItem[] {
        if (this.auth.can(Platform.timeTracking.permission.view) || !timesheetEntry.isApproved) {
            return [editOption, deleteOption];
        }

        if (timesheetEntry.project?.approvalFlow === 'manager' && this.auth.supervises(this.employeeId)) {
            return [editOption, deleteOption];
        }

        return [];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TimesheetEntry.with(['project', 'employee', 'timeClockEntry']).where('employeeId', this.employeeId)
        );
    }
}
