import { Size } from '@app/components/avatar/avatar.component';
import { Task } from '@app/models/tasks/task.model';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TitlecasePipe } from '@app/pipes';
import { Employee } from '@models/employee/employee.model';
import { MultiAvatarColumn } from '@table/classes/multi-avatar-column.class';

export class AssignedToColumn extends MultiAvatarColumn<Task> {
    titleCasePipe = new TitlecasePipe();

    title = 'table.all-company-tasks.toBeCompletedBy';
    property = 'assignees';
    type: CellDisplay = CellDisplay.singleAndMultiAvatar;
    sortable = false;
    size: Size = 'medium';

    getDisplayPropertyValue(task: Task): string | null {
        let assignedTo = 'table.all-company-tasks.unassigned';

        if (task.assigneeType) {
            assignedTo = task.assigneeType;
        }
        return assignedTo;
    }

    getEmployees(task: Task): Employee[] {
        return task.assignees ?? [];
    }

    getMaximumEmployeesToShow(): number {
        return 5;
    }

    getRowStyling(task: Task): { [key: string]: string } {
        if (!task.assigneeType && !task.assignees.length) {
            return { color: 'var(--grey-200)', 'font-style': 'italic' };
        }
    }
}
