import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';
import {
    TaskTemplateAssigneeTypeDisplayOptions,
    TaskTemplateAssigneeTypeSubjectMapDisplayOptions,
} from '@app/types/translatables/task-template-types-display.options';
import { Employee } from '@models/employee/employee.model';
import { TaskTemplate } from '@models/tasks/task-template.model';
import { MultiAvatarColumn } from '@table/classes/multi-avatar-column.class';

export class AssignedToColumn extends MultiAvatarColumn<TaskTemplate> {
    title = 'table.task-templates.toBeCompletedBy';
    property = 'assignees';
    type: CellDisplay = CellDisplay.singleAndMultiAvatar;
    sortable = false;

    getDisplayPropertyValue(taskTemplate: TaskTemplate): Translatable {
        if (taskTemplate.assigneeType === 'subject') {
            return (
                TaskTemplateAssigneeTypeSubjectMapDisplayOptions[taskTemplate.taskType] ??
                TaskTemplateAssigneeTypeDisplayOptions.unassigned
            );
        }

        return (
            TaskTemplateAssigneeTypeDisplayOptions[taskTemplate.assigneeType] ??
            TaskTemplateAssigneeTypeDisplayOptions.unassigned
        );
    }

    getEmployees(taskTemplate: TaskTemplate): Employee[] {
        return taskTemplate.assignees;
    }

    getMaximumEmployeesToShow(): number {
        return 5;
    }

    getRowStyling(taskTemplate: TaskTemplate): { [key: string]: string } | null {
        if (!taskTemplate.assigneeType && !taskTemplate.assignees.length) {
            return { color: 'var(--grey-200)', 'font-style': 'italic' };
        }

        return null;
    }
}
